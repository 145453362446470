<template>
  <div class="enterpriseInfo">
    <div class="search">
      <el-button @click="addClick" type="primary" size="small">新建</el-button>
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px">
        <el-form-item label="员工姓名">
          <el-input v-model="searchForm.ygxm" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="员工账号">
          <el-input v-model="searchForm.ygzh" placeholder="请输入员工账号"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button @click="getUserList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table_box">
      <el-table stripe border class="table" :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
        <el-table-column prop="ptmc" label="平台名称" align="center"></el-table-column>
        <el-table-column prop="xmmc" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="xmlx" label="项目类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.xmlx == 2 ? '采集项目' : '标注项目' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ygxm" label="员工姓名" align="center"></el-table-column>
        <el-table-column prop="ygzh" label="员工账号" align="center"></el-table-column>
        <el-table-column prop="rzrq" label="日报日期" align="center"></el-table-column>
        <el-table-column prop="zxq" label="总需求" align="center"></el-table-column>
        <el-table-column prop="rzb" label="日指标" align="center"></el-table-column>
        <el-table-column prop="wcny" label="完成内业" align="center"></el-table-column>
        <el-table-column prop="wcwy" label="完成外业" align="center"></el-table-column>
        <el-table-column prop="zbwcl" label="日指标完成率" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div slot="avatar" class="operation">
              <span @click="eait(scope.row)">编辑</span>
              <span @click="delect(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_pagination">
        <pagination :total="total" :currentPage="searchForm.page" :pageSize="searchForm.size" @pagination="pageChange"/>
      </div>
      <!-- 添加采集部 -->
      <el-dialog destroy-on-close :title="titles + '采集项目'" :visible.sync="dialogVisibleCjb" width="80%">
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisibleCjb = false">取 消</el-button>
          <el-button size="small" class="butColor" type="primary" @click="saveFromCjb('addForm1')">确 定</el-button>
        </span>

        <el-form ref="addForm1" :inline="true" :model="addForm">
          <el-table :data="addForm.cjbData" stripe border style="width: 100%; margin-bottom: 20px">
            <el-table-column prop="xh" type="index" label="序号"/>
            <el-table-column label="平台名称" width="100">
              <template slot-scope="scope">
                <el-form-item>
                  <span>{{ scope.row.ptmc }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="项目名称" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.xmmc }}</span>
              </template>
            </el-table-column>

            <el-table-column label="账号">
              <template slot-scope="scope">
                <el-form-item :prop="`cjbData.${scope.$index}.ygzh`"
                              :rules="[{ required: true, message: '请输入账号', trigger: 'blur' }]">
                  <el-input class="ipt" type="text" placeholder="账号" v-model="scope.row.ygzh"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="总需求">
              <template slot-scope="scope">
                <el-form-item
                    :prop="`cjbData.${scope.$index}.zxq`"
                    :rules="[{ required: true, message: '请输入总需求', trigger: 'blur' }]">
                  <el-input
                      class="ipt"
                      @input="zbwclInput1(scope.$index)"
                      type="text"
                      placeholder="总需求"
                      v-model="scope.row.zxq"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="完成内业">
              <template slot-scope="scope">
                <el-form-item
                    :prop="`cjbData.${scope.$index}.wcny`"
                    :rules="[{ required: true, message: '请输入完成内业', trigger: 'blur' }]">
                  <el-input
                      class="ipt"
                      @input="zbwclInput1(scope.$index)"
                      type="text"
                      placeholder="完成内业"
                      v-model="scope.row.wcny"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="完成外业">
              <template slot-scope="scope">
                <el-form-item
                    :prop="`cjbData.${scope.$index}.wcwy`"
                    :rules="[{ required: true, message: '请输入完成外业', trigger: 'blur' }]">
                  <el-input
                      class="ipt"
                      @input="zbwclInput1(scope.$index)"
                      type="text"
                      placeholder="完成外业"
                      v-model="scope.row.wcwy"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="日指标">
              <template slot-scope="scope">
                <el-form-item :prop="`cjbData.${scope.$index}.rzb`">
                  <el-input
                      class="ipt"
                      @input="zbwclInput1(scope.$index)"
                      disabled
                      type="number"
                      placeholder="日指标"
                      v-model="scope.row.rzb"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="日指标完成率">
              <template slot-scope="scope">
                <el-form-item :prop="`cjbData.${scope.$index}.zbwcl`">
                  <el-input class="ipt" disabled placeholder="自动计算" type="number" v-model="scope.row.zbwcl"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue';
import {getPage, postSave, ptglDelete} from '@/request/api/ptgl.js';

export default {
  components: {pagination},

  data() {
    return {
      tableData: [],
      ptList: [],
      xmList: [],
      total: 0,
      searchForm: {},
      addForm: {
        cjbData: [
          {
            ptid: '',
            xmlx: '',
            xmid: '',
            rzb: '',
            zbwcl: '',
            wcny: '',
            wcwy: '',
            ygzh: ''
          }
        ]
      },
      dialogVisibleCjb: false,
      titles: '新建',

      loading: true,
      // 菜单禁用
      infoState: false
    };
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    // 新建
    addClick() {
      this.infoState = false;
      this.titles = '新建';
      this.$nextTick(() => {
        this.dialogVisibleCjb = true;
      });
      // this.getPtList();
      this.getXmList();
    },
    //
    pageChange({page, limit}) {
      this.searchForm.page = page;
      this.searchForm.size = limit;
      this.getUserList();
    },

    // 获取用户列表
    getUserList() {
      getPage('/pro11/ygrb/page', {
        page: 1,
        size: 10,
        xmlx: 2,
        ...this.searchForm
      }).then((res) => {
        if (res.data.state == 'success') {
          this.tableData = res.data.data;
          this.total = res.data.size;
        }
      });
    },

    // 保存
    saveFromCjb(dom) {
      let url = '/pro11/ygrb/adds';
      let arr = [];
      if (this.titles == '修改' && this.addForm.cjbData[0].id != '') {
        url = '/pro11/ygrb/save';
        arr = {...this.addForm.cjbData};
      } else {
        url = '/pro11/ygrb/adds';
        arr = [...this.addForm.cjbData];
      }
      this.$refs[dom].validate((valid) => {
        if (valid) {
          postSave(url, arr).then((res) => {
            if (res.data.state == 'success') {
              this.dialogVisibleCjb = false;
              this.getUserList();
              this.$message.success(res.data.msg);
            }
          });
        }
      });
    },
    zbwclInput1(idx) {
      this.addForm.cjbData[idx].zbwcl = (
          (parseFloat(this.addForm.cjbData[idx].wcny) + parseFloat(this.addForm.cjbData[idx].wcwy)) /
          parseFloat(this.addForm.cjbData[idx].rzb)
      ).toFixed(2);
    },

    getXmList() {
      getPage('/pro11/xmgl/list').then((res) => {
        if (res.data.state == 'success') {
          this.xmList = res.data.data;
          let cjb2 = this.xmList.filter((item) => item.xmlx == 2);
          console.log(cjb2);

          let cjbObj = [];
          if (cjb2.length > 0) {
            cjb2.forEach((item) => {
              cjbObj.push({
                ptid: item.ptid,
                xmlx: item.xmlx,
                xmid: item.id,
                ptmc: item.ptmc,
                xmmc: item.xmmc,
                rzb: item.rzb,
                ygzh: '',
                ygxm: window.localStorage.getItem('user'),
                zbwcl: '',
                wcny: '',
                wcwy: ''
              });
            });
            this.addForm.cjbData = cjbObj;
          } else {
            this.addForm.cjbData = [
              {
                ptid: '',
                xmlx: '',
                xmid: '',
                ptmc: '',
                xmmc: '',
                rzb: '',
                ygzh: '',
                ygxm: window.localStorage.getItem('user'),
                zbwcl: '',
                wcny: '',
                wcwy: ''
              }
            ];
            this.$message({
              message: '该平台名称下没有项目',
              type: 'warning'
            });
          }
        }
      });
    },

    /**
     * @description: loadingg 关闭
     * @param {*}
     */

    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    /**
     * @description:  修改
     * @param {*} data
     */
    eait(data) {
      this.titles = '修改';
      this.dialogVisibleCjb = true;
      this.addForm.cjbData = [data];
    },

    /**
     * @description: 删除
     * @param {*} data
     */
    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.ptmc} 信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            ptglDelete(`/pro11/ygrb/delete/${data.id}`).then((res) => {
              if (res.data.state == 'success') {
                this.loading = true;
                this.$message.success(res.data.msg);
                this.getUserList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
    }
  }
};
</script>
<style scoped lang="less">
@import '~@/style/colorPublic';

.butColor {
  // background: @but_color;
  background: #409eff;
}

.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}

.operation {
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
    margin-right: 1vh;
  }
}


.pageStyle {
  text-align: @pageAlign;
  margin-top: 1vh;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchForm /deep/ .el-input__inner {
  // height: 4vh !important;
  height: 30px !important;
  line-height: 4vh !important;
}

.searchForm /deep/ .el-button--default {
  // height: 4vh !important;
  height: 30px !important;
  padding: 9px 19px;
}

.accept {
  cursor: pointer;
  background: #3366cc;
  color: #fff;
  border-radius: 5px;
  padding: 0.5vh;
  box-sizing: border-box;
}

// .search{
//     border: 1px solid #ccc;
//     padding: 1vh;
//     box-sizing: border-box;
// }
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.receip /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
}

.receip /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.receip /deep/ .avatar-uploader-icon {
  color: #8c939d;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
  text-align: center;
}

.receip /deep/ .avatar {
  width: 10vw;
  height: 10vw;
  display: block;
}

@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    width: 380px;
    line-height: 5vh !important;
  }

  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }

  .searchBut {
    position: absolute;
    right: 0;
    top: 0;
  }

  .table {
    overflow-y: auto;
  }
}

/deep/ .ipt {
  input {
    width: 180px !important;
  }
}

/deep/ .ipt1 {
  input {
    width: 140px !important;
  }
}
</style>
